html,
body {
   margin: 0;
   padding: 0;
   border: 0;
   height: 100vh;
   width: 100vw;

   font-family: "Arial Rounded MT Bold", sans-serif;
   overflow: hidden;
}
